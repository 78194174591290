html,
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
}

* {
    box-sizing: border-box;
}

#root {
    height: 100%;
    width: 100%;
}
