.spinner {
    --spinner-size: 4rem;
    --spinner-color: #e62e7b;

    &.sizeSmall {
        --spinner-size: 1rem;
    }

    &.sizeLarge {
        --spinner-size: 4rem;
    }

    &.colorPink {
        --spinner-color: #e62e7b;
    }

    &.colorBlue {
        --spinner-color: #4862e6;
    }

    &.colorWhite {
        --spinner-color: #fff;
    }

    display: inline-block;
    position: relative;
    width: var(--spinner-size);
    height: var(--spinner-size);

    div {
        position: absolute;
        width: var(--spinner-size);
        height: var(--spinner-size);
        border-width: calc(var(--spinner-size) / 8);
        border-style: solid;
        border-radius: 50%;
        border-color: transparent;
        border-top-color: var(--spinner-color);
        animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }

    div:nth-child(1) {
        animation-delay: -0.45s;
    }

    div:nth-child(2) {
        animation-delay: -0.3s;
    }

    div:nth-child(3) {
        animation-delay: -0.15s;
    }

    &.center {
        position: absolute;
        left: calc(50% - var(--spinner-size) / 2);
        top: calc(50% - var(--spinner-size) / 2);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
